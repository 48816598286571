export enum ConversionStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  RETURNED = "RETURNED",
  REFUNDED = "REFUNDED",
  REVERSED = "REVERSED",
  ONHOLD = "ONHOLD",
  BLOCKED = "BLOCKED",
  COMPLETED = "COMPLETED",
}

export const HOLD = [ConversionStatus.PROCESSING, ConversionStatus.ONHOLD];
export const SUCCESS = [ConversionStatus.SUCCESS, ConversionStatus.RETURNED, ConversionStatus.COMPLETED];
export const REFUNED = [ConversionStatus.REFUNDED, ConversionStatus.REVERSED];
export const FAIL = [ConversionStatus.FAILED, ConversionStatus.BLOCKED];

export const ConversionStatusOpt = [
  {
    key: ConversionStatus.PENDING,
    text: "PENDING",
  },
  {
    key: ConversionStatus.PROCESSING,
    text: "PROCESSING",
  },
  {
    key: ConversionStatus.ONHOLD,
    text: "ON HOLD",
  },
  {
    key: ConversionStatus.SUCCESS,
    text: "SUCCESS",
  },
  {
    key: ConversionStatus.RETURNED,
    text: "RETURNED",
  },
  {
    key: ConversionStatus.REFUNDED,
    text: "REFUNDED",
  },
  {
    key: ConversionStatus.REVERSED,
    text: "REVERSED",
  },
  {
    key: ConversionStatus.FAILED,
    text: "ONHOLD",
  },
  {
    key: ConversionStatus.BLOCKED,
    text: "BLOCKED",
  },
];
