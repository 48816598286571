import { defineComponent } from "vue";

export default defineComponent({
  props: {
    handleClick: {
      type: Function,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    totalDisplay: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    displayPrev(): boolean {
      if (this.totalDisplay >= this.lastPage) {
        return false;
      }

      return this.currentPage > 3;
    },
    displayNext(): boolean {
      if (this.totalDisplay >= this.lastPage) {
        return false;
      }

      return this.currentPage < this.lastPage - this.totalDisplay + 3;
    },
    listPage(): number[] {
      if (this.lastPage <= this.totalDisplay) {
        return this.range(1, this.lastPage);
      }

      const inRangeFirstPage = this.currentPage < 3;
      const inRangeEndPage = this.lastPage < this.currentPage - 2 + this.totalDisplay;

      if (inRangeFirstPage) {
        const firstPage = 1;
        const endPage = this.totalDisplay;

        return this.range(firstPage, endPage);
      }

      if (inRangeEndPage) {
        const firstPage = this.lastPage - this.totalDisplay + 1;
        const endPage = this.lastPage;

        return this.range(firstPage, endPage);
      }

      const firstPage = this.currentPage - 2;
      const endPage = firstPage + this.totalDisplay - 1;

      return this.range(firstPage, endPage);
    },
  },
  methods: {
    moveTo(page: number): void {
      this.handleClick(page);
    },
    range(start: number, end: number) {
      return Array(end - start + 1)
        .fill(0)
        .map((_, idx) => start + idx);
    },
  },
  render() {
    return (
      <div class={`pagination ${this.className}`}>
        {this.displayPrev && (
          <div
            class={`page mr-1 py-2 px-3 select-none border rounded-sm border-orange-500 border-solid font-medium cursor-pointer`}
            onClick={() => this.moveTo(1)}
          >
            {"<<"}
          </div>
        )}
        {this.listPage.length > 0 &&
          this.listPage.map((item: number) => (
            <div
              class={`page mr-1 py-2 px-3 select-none border rounded-sm border-orange-500 border-solid font-medium ${
                this.currentPage === item ? "active" : ""
              } ${this.currentPage !== item ? "cursor-pointer" : ""}`}
              key={item}
              onClick={() => (this.currentPage !== item ? this.moveTo(item) : null)}
            >
              {item}
            </div>
          ))}
        {this.displayNext && (
          <div
            class={`page py-2 px-3 select-none border rounded-sm border-orange-500 border-solid font-medium cursor-pointer`}
            onClick={() => this.moveTo(this.lastPage)}
          >
            {">>"}
          </div>
        )}
      </div>
    );
  },
});
