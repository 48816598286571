import { defineComponent, PropType } from "vue";
import { format } from "date-fns";
import { Http } from "@/types/http";
import { Loading } from "@twjs/vue-loading";
import { Notice } from "@twjs/vue-notice";
import Pagination from "@/components/Pagination";
import { DataTable } from "@/components/DataTable";
import { finalize, map } from "rxjs/operators";
import { ConversionHistoryModal } from "./user/conversion/ConversionDetail";
import { get } from "js-cookie";
import { Auth } from "@/types/auth";
import { Conversion } from "@/types/user";
import store from "@/store";
import { PaytrippaPointsFilter } from "@/types/filter";

const DEFAULT_TOKEN_KEY = "auth.token";

interface ConversionFilter extends PaytrippaPointsFilter {
  userId: string;
}

const initFilter: ConversionFilter = {
  userId: "",
  source: "0",
  fromDate: "",
  endDate: "",
};

const FilterBar = defineComponent({
  props: {
    onFilter: { type: Function, required: true },
  },
  data() {
    return {
      filters: { ...initFilter },
    };
  },
  beforeMount() {
    this.filters = { ...store.state.prevState.conversionHistory.filters };
  },
  render() {
    return (
      <section class="flex items-end justify-end my-4">
        <pw-input type="text" label="User Id" placeholder="Enter id value" class="mr-2" v-model={this.filters.userId} />
        <pw-select
          label="Source"
          class="c-select mr-2"
          v-model={this.filters.source}
          options={[
            { key: "0", text: "All" },
            { key: "-1", text: "Withdrawal" },
            { key: "1", text: "Deposit" },
          ]}
        />
        <pw-input type="Date" label="Begin Date" class="mr-2" v-model={this.filters.fromDate} />
        <pw-input type="Date" label="End Date" class="mr-2" v-model={this.filters.endDate} />
        <w-pb type="button" class="square-btn user_search-btn mr-2" onClick={() => this.onFilter(this.filters)} icon="Search" />
        <w-pb
          tooltip-text="Clear all filter"
          type="button"
          class="square-btn user_clear-btn tooltip relative"
          onClick={() => (this.filters = { ...initFilter })}
          icon="ClearFilter"
        />
      </section>
    );
  },
});

interface ConversionHistory extends Conversion {
  user: string;
}

export default defineComponent({
  components: { ConversionHistoryModal },
  props: {
    $http: { type: Object as PropType<Http>, required: true },
    $loading: { type: Object as PropType<Loading>, required: true },
    $notice: { type: Object as PropType<Notice>, required: true },
    $auth: { type: Object as PropType<Auth>, required: true },
  },
  data() {
    return {
      data: [],
      page: 1,
      lastPage: 1,
      size: 25,
      keywords: "",
      filters: { ...initFilter },
      isOpen: false,
      selectedId: -1,
    };
  },
  beforeMount() {
    const { page, filters } = store.state.prevState.conversionHistory;
    this.page = page;
    this.filters = { ...filters };
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
    store.commit("setPrevState", {
      conversionHistory: {
        page: this.page,
        filters: { ...this.filters },
      },
    });
  },
  methods: {
    buildUrl() {
      let url = `/admin/transaction/points/history?page=${this.page}&page_size=${this.size}`;
      const { userId, source, endDate, fromDate } = this.filters;

      if (userId.length > 0) {
        url += `&user_id=${userId}`;
      }

      if (source && source !== "0") {
        url += `&source=${source}`;
      }

      if (fromDate.length > 0) {
        url += `&start_date=${fromDate}`;
      }

      if (endDate.length > 0) {
        url += `&end_date=${endDate}`;
      }

      return url;
    },
    fetchData() {
      this.$loading.show();
      const url = this.buildUrl();

      this.$http
        .get(url)
        .pipe(
          map((res: any) => res.data),
          map((data: any) => {
            this.lastPage = data.lastPage;

            return data.data.map(
              (item: any): ConversionHistory => ({
                id: item.id,
                date: item.created_at && new Date(item.created_at),
                time: item.created_at && format(new Date(item.created_at), "hh:mm A"),
                points: item.point,
                source: item.source,
                sponsorship: item.sponsorship,
                sponsorshipId: item.sponsorship_id,
                address: item.address,
                note: item.note,
                tripId: item.trip_id,
                user: item.full_name,
              }),
            );
          }),
          finalize(this.$loading.hide),
        )
        .subscribe(
          (data) => {
            this.data = data;
          },
          (err: Error) => {
            const error = this.$http.getErrorData(err);
            this.$notice.e("Failed to load transaction history data: " + error.message);
          },
        );
    },
    movePage(page: number) {
      this.page = page;
      this.fetchData();
    },
    handleFilter(userFilters: ConversionFilter) {
      this.filters = userFilters;
      this.fetchData();
    },
    onHide(reload = false) {
      this.selectedId = -1;
      this.isOpen = false;
      if (reload) {
        this.page = 1;
        this.fetchData();
      }
    },
    onOpen(id: number) {
      this.isOpen = true;
      this.selectedId = id;
    },
    splitText(text: string) {
      if (!text) {
        return "";
      }

      if (text.length > 30) {
        return text.slice(0, 30) + "...";
      }

      return text;
    },
    async exportCSV() {
      const token = get(DEFAULT_TOKEN_KEY);
      if (!token) {
        this.$notice.e("Please login to continue.");
        this.$auth.logout();
        return;
      }

      try {
        this.$loading.show();
        const response = await fetch(`${process.env.VUE_APP_HTTP_ENDPOINT}/admin/transaction/points/history/csv`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          this.$notice.e("Failed to export csv!");
          return;
        }

        const body = await response.text();

        const a = document.createElement("a");
        a.href = "data:attachment/csv," + encodeURIComponent(body);
        a.target = "_blank";
        a.download = "transactions-data.csv";
        a.classList.add("hidden");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.$notice.s("Export csv successfully!");
      } catch (e) {
        this.$notice.e("Failed to get csv: " + (e as any).message);
      } finally {
        this.$loading.hide();
      }
    },
  },
  render() {
    return (
      <div class="p-10 overflow">
        <w-ph text="Conversions" onRenderAction={() => <w-pb icon="Page" text="Export CSV" onClick={this.exportCSV} />} />
        <FilterBar onFilter={this.handleFilter} />
        <DataTable
          className="mt-10"
          headers={[
            { title: "Conversion ID", class: "text-center" },
            { title: "User", class: "text-center" },
            { title: "Date", class: "text-enter" },
            { title: "Time", class: "text-center" },
            { title: "Sponsorship", class: "text-center" },
            { title: "Trip Id", class: "text-center" },
            { title: "PayTrippa Points", class: "text-center" },
            { title: "Source", class: "text-center" },
          ]}
          items={this.data}
          onRenderItem={(o: ConversionHistory) => (
            <tr class="cursor-pointer" title="Click to get more detail" onClick={() => this.onOpen(o.id)}>
              <td class="text-center">{o.id}</td>
              <td class="text-center">{o.user}</td>
              <td class="text-center">{o.date && format(o.date, "DD/MM/YYYY")}</td>
              <td class="text-center">{o.time}</td>
              <td
                class="text-center"
                onClick={() => {
                  if (!o.sponsorshipId || o.sponsorshipId <= 0) {
                    return;
                  }
                  this.$router.push(`/sponsorships/${o.sponsorshipId}/details`);
                }}
              >
                {o.sponsorship}
              </td>
              <td
                class="text-center"
                onClick={() => {
                  if (!o.tripId || o.tripId <= 0) {
                    return;
                  }

                  this.$router.push(`/participants/trip/${o.tripId}`);
                }}
              >
                {o.tripId > -1 ? o.tripId : "-"}
              </td>
              <td class="text-center">{o.points}</td>
              <td class="text-center flex items-center justify-center cursor-pointer">
                <span
                  class="tooltip relative text-center pr-0"
                  tooltip-text={o.source === 1 ? "Receive transaction" : "Withdrawal transaction"}
                >
                  {o.source === 1 ? (
                    <i class="ms-Icon ms-Icon--CirclePlus green-color text-2xl font-black"></i>
                  ) : (
                    <i class="ms-Icon ms-Icon--SkypeCircleMinus red-color text-2xl font-black"></i>
                  )}
                </span>
              </td>
            </tr>
          )}
        />
        <Pagination
          lastPage={this.lastPage}
          currentPage={this.page}
          totalDisplay={5}
          handleClick={this.movePage}
          class="flex mt-10 justify-end"
        />
        <conversion-history-modal isOpen={this.isOpen} onHide={this.onHide} id={this.selectedId} />
      </div>
    );
  },
});
