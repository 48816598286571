import { FAIL, HOLD, REFUNED, SUCCESS } from "@/types/conversion-status";
import { Http } from "@/types/http";
import { Loading } from "@twjs/vue-loading";
import { Notice } from "@twjs/vue-notice";
import { defineComponent, PropType } from "@vue/runtime-core";
import { format } from "date-fns";
import { finalize, map } from "rxjs/operators";
import TextArea from "@/components/TextArea";

const ConversionForm = defineComponent({
  components: { TextArea },
  props: {
    formData: {
      type: Object,
      required: false,
      default: () => ({
        id: -1,
        user_id: -1,
        full_name: "",
        point: 0,
        sponsorship_id: "",
        sponsorship: "",
        trip_id: -1,
        created_at: "",
        source: 0,
        in_out: 0,
        trx_id: -1,
        note: "",
        address: "",
      }),
    },
  },

  methods: {
    statusLabel(value: any) {
      if (HOLD.includes(value)) {
        return "sts-scheduled";
      }

      if (SUCCESS.includes(value)) {
        return "sts-send";
      }

      if (REFUNED.includes(value)) {
        return "sts-re-transfer";
      }

      if (FAIL.includes(value)) {
        return "sts-blocked";
      }

      return "sts-created ";
    },
  },
  render() {
    return (
      <div class="w-full modal-content-height overflow-y-auto">
        <form class="px-8 py-6 w-full">
          <div class="flex flex-row mb-8">
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">Id</label>
              <p class="disable-text mt-2">{this.formData.id === -1 ? "" : this.formData.id}</p>
            </div>
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">User</label>
              <p class="disable-text mt-2">
                {this.formData.full_name} - {this.formData.user_id}
              </p>
            </div>
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">Created At</label>
              <p class="disable-text mt-2">
                {this.formData.created_at && format(new Date(this.formData.created_at), "YYYY/MM/DD hh:mm A")}
              </p>
            </div>
          </div>
          <div class="flex flex-row mb-8">
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">TripId</label>
              <p class="disable-text mt-2">{this.formData.trip_id}</p>
            </div>
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">Sponsorship</label>
              <p class="disable-text mt-2">
                {this.formData.sponsorship} - {this.formData.sponsorship_id}
              </p>
            </div>
            <div class="sm:w-1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="text-default-600 font-semibold">Points</label>
              <p class="disable-text mt-2">{this.formData.point}</p>
            </div>
          </div>
          <div class="mb-8">
            <div class="w-full">
              <label class="text-default-600 font-semibold">Address:</label>
              <p class="disable-text mt-2">{this.formData.address}</p>
            </div>
          </div>

          <div class="flex flex-row mb-8">
            <div class="sm:w-1 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="text-default-600 font-semibold">Convert/Deposit</label>
              <div class="disable-text mt-2">
                {this.formData.in_out === 1 ? (
                  <i class="ms-Icon ms-Icon--CirclePlus green-color text-2xl font-black"></i>
                ) : (
                  <i class="ms-Icon ms-Icon--SkypeCircleMinus red-color text-2xl font-black"></i>
                )}
              </div>
            </div>
            <div class="sm:w-1 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <label class="text-default-600 font-semibold">Transaction Convert</label>
              <p class="disable-text mt-2">{this.formData.trx_id === -1 ? "-" : this.formData.trx_id}</p>
            </div>
          </div>

          <div class="mb-8">
            <div class="w-full">
              <label class="text-default-600 font-semibold">Note:</label>
              <p class="disable-text mt-2">{this.formData.note}</p>
            </div>
          </div>
        </form>
      </div>
    );
  },
});

export const ConversionHistoryModal = defineComponent({
  props: {
    id: { type: Number, required: true },
    isOpen: { type: Boolean, required: false, default: false },
    onHide: { type: Function, required: true },
    $http: { type: Object as PropType<Http>, required: true },
    $loading: { type: Object as PropType<Loading>, required: true },
    $notice: { type: Object as PropType<Notice>, required: true },
  },
  data() {
    return {
      formData: {
        id: -1,
        user_id: -1,
        trip_id: -1,
        sponsorship_id: -1,
        created_at: "",
        sponsorship: "",
        address: "",
        full_name: "",
        point: -1,
        source: -1,
        in_out: -1,
        trx_id: -1,
        note: "",
      },
      displayTransaction: false,
      showConfirm: false,
    };
  },
  watch: {
    id(value: number) {
      if (value === -1) {
        return;
      }

      this.fetchData(value);
    },
  },
  methods: {
    fetchData(id: number) {
      this.$loading.show();
      this.$http
        .get(`/admin/transactions/points/detail/${id}`)
        .pipe(
          map((res: any) => res.data),
          map((data: any) => {
            if (!data) {
              return {
                id: -1,
                trx_id: -1,
                trip_id: -1,
                user_id: -1,
                full_name: "",
                sponsorship_id: -1,
                sponsorship: "",
                note: "",
                created_at: "",
                point: -1,
                source: 1,
                in_out: -1,
                address: "",
              };
            }

            return {
              id: data.id,
              user_id: data.user_id,
              sponsorship_id: data.sponsorship_id,
              trip_id: data.trip_id,
              created_at: data.created_at,
              point: data.point,
              note: data.note,
              full_name: data.full_name,
              address: data.address,
              sponsorship: data.sponsorship,
              source: data.source,
              in_out: data.in_out,
              trx_id: data.trx_id,
            };
          }),
          finalize(this.$loading.hide),
        )
        .subscribe(
          (data) => {
            this.formData = data;
          },
          (err: Error) => {
            const error = this.$http.getErrorData(err);
            this.$notice.e("Failed to load data: " + error.message);
            this.formData = {
              id: -1,
              trx_id: -1,
              trip_id: -1,
              user_id: -1,
              full_name: "",
              sponsorship_id: -1,
              sponsorship: "",
              note: "",
              created_at: "",
              point: -1,
              source: 1,
              in_out: -1,
              address: "",
            };
          },
        );
    },
  },
  render() {
    return (
      <modal class="modal-min-height" customStyles={{ width: "768px", minHeight: "450px" }} show={this.isOpen} onHide={this.onHide}>
        <w-tab text="Conversion" />
        {this.isOpen && <ConversionForm formData={this.formData} />}
      </modal>
    );
  },
});
